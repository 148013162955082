body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker-popper {
    z-index: 99
}

.react-datepicker__input-container input {
    max-width: 105px !important;
    background-color: #ffffff00 !important;
}
div.expansion-panel:hover {
    background: #e7e7e9;
}

div.expansion-panel:hover div div  div.header {
    font-weight: bold;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.bolden {
    color: white;
}

.fade-enter {
    opacity: 0.01;
}
.fade-enter-active {
    opacity: 1;
    -webkit-transition: opacity 500ms ease-in;
    transition: opacity 500ms ease-in;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0.01;
    -webkit-transition: opacity 500ms ease-in;
    transition: opacity 500ms ease-in;
}

.Modal_ModalContent__2OXKX {
    position: fixed;
    margin: 0 auto;
    height: 200px;
    width: 400px;
    z-index: 501;
    background-color: white;
    top: 20%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-shadow: 1px 1.5px 1px black;
    padding: 20px;
}
