body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker-popper {
    z-index: 99
}

.react-datepicker__input-container input {
    max-width: 105px !important;
    background-color: #ffffff00 !important;
}
div.expansion-panel:hover {
    background: #e7e7e9;
}

div.expansion-panel:hover div div  div.header {
    font-weight: bold;
}